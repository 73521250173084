import React, { useState } from "react";
import { colors } from "../../theme/Colors";
import { useNavigate } from "react-router-dom";

const ServiceDisplayItem = ({
  width,
  height,
  marginBottom,
  info,
  image,
  title,
  widthCheck,
}) => {
  const [view, setView] = useState(false);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => {
        setHover(false);
      }}
      onClick={
        info ? () => setView(view ? false : true) : () => navigate("/contactUs")
      }
      style={{
        width: width,
        height: height,
        marginBottom: marginBottom,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        WebkitBoxShadow: hover
          ? "0px 0px 10px 5px #00000080"
          : "0px 0px 0px 0px #00000050",
        transition: " all 0.3s",
        cursor: "pointer",
      }}
    >
      <img
        alt="image check"
        src={image}
        style={{ height: "100%", width: "100%" }}
      />
      <div
        style={{
          position: "absolute",
          bottom: -20,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {view ? null : (
          <div
            style={{
              marginTop: 40,
              paddingTop: 10,
              paddingBottom: 10,
              width: 260,
              backgroundColor: colors.secondary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#FFFFFF",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {title}
          </div>
        )}
      </div>

      <div
        onMouseLeave={() => setView(false)}
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            height: view ? "100%" : 0,
            backgroundColor: "#FFFFFF",
            zIndex: 3,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            transition: "height 0.3s",
          }}
        >
          {view ? (
            <div
              style={{
                // marginTop: 40,
                height: 40,
                width: 260,
                backgroundColor: colors.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFFFFF",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {title}
            </div>
          ) : null}
          {info ? (
            <div
              style={{
                width: "80%",
                height: "90%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: widthCheck > 899 ? 16 : info.length > 100 ? 10 : 12,
              }}
            >
              {info}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ServiceDisplayItem;
