import React, { useState } from "react";
import "./App.css";
import {
  AboutUsDisplay,
  ContactUsDisplay,
  EmailPopUp,
  MenuPopUp,
  ServiceDisplay,
  TopNavBar,
  useWindowDimensions,
} from "./components";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AboutUs, ContactUs, Home, Services } from "./screens";

function App() {
  const { width, height } = useWindowDimensions();
  const [menu, setMenu] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState("contact");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home height={height} width={width} />} />
        <Route
          path="/about"
          element={
            <AboutUs
              height={height}
              width={width}
              menu={menu}
              setMenu={setMenu}
            />
          }
        />
        <Route
          path="/contactUs"
          element={
            <ContactUs
              height={height}
              width={width}
              menu={menu}
              setMenu={setMenu}
            />
          }
        />
        <Route
          path="/services"
          element={
            <Services
              height={height}
              width={width}
              menu={menu}
              setMenu={setMenu}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
