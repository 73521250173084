import React, { useEffect, useState } from "react";
import {
  AboutUsDisplay,
  ContactUsDisplay,
  MenuPopUp,
  TopNavBar,
} from "../../components";
import { Outlet, Link } from "react-router-dom";
import { colors } from "../../theme/Colors";

const AboutUs = ({ width, height }) => {
  const [menu, setMenu] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 100,

          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          alt="content home"
          src={require("../../images/new-icons/mainLogo.png")}
          style={{ height: "95%", marginLeft: width > 899 ? "9%" : null }}
        />
        <div style={{ flex: 1 }} />
        {width > 899 ? (
          <>
            <Link to="/" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  fontSize: 18,
                  width: 80,
                  color: "#000000",
                }}
              >
                HOME
              </div>
            </Link>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  width: 80,
                  fontSize: 18,
                }}
              >
                ABOUT
              </div>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  width: 100,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                SERVICES
              </div>
            </Link>
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", marginRight: "10%" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  width: 100,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                CONTACT
              </div>
            </Link>
          </>
        ) : (
          <div
            style={{
              fontWeight: "bold",
              width: 100,
              fontSize: 18,
              color: "#000000",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div onClick={() => setMenu(true)}>MENU</div>
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
                height: menu ? 120 : 0,
                backgroundColor: "#ffffff",
                zIndex: 4,
                right: 0,
                width: "100%",
                top: 0,
                justifyContent: "space-evenly",
                transition: "height 0.3s",
              }}
            >
              <>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      width: 80,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    HOME
                  </div>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,

                      textAlign: "center",
                    }}
                  >
                    ABOUT
                  </div>
                </Link>
                <Link to="/services" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    SERVICES
                  </div>
                </Link>
                <Link to="/contactUs" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    CONTACT
                  </div>
                </Link>
              </>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          flex: width > 899 ? 1 : null,
          height: width > 899 ? null : 800,
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <div
          style={{
            // flex: 0.5,
            backgroundColor: colors.primary,
            width: "100%",
            position: "fixed",
            height: width > 899 ? "40%" : "20%",
            bottom: 0,
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            height: "90%",
            width: "90%",

            zIndex: 2,
            maxWidth: 1600,
            // backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flexDirection: width > 899 ? "row" : "column",
          }}
        >
          <div
            style={{
              height: width > 899 ? "100%" : 800,
              flex: width > 899 ? 0.6 : null,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                height: width > 899 ? 500 : null,

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: width > 899 ? null : 450,
              }}
            >
              <img
                alt="content home"
                src={require("../../images/new-icons/aboutUsText.png")}
                style={{
                  height: width > 899 ? 120 : 100,
                  marginBottom: width > 899 ? 10 : null,
                }}
              />

              <div
                style={{
                  width: "90%",
                  fontSize: width > 899 ? 20 : 16,
                  textAlign: "center",
                  backgroundColor: "#FFFFFF",
                  padding: 10,
                }}
              >
                The relationship between work and health is directly related,
                where the working environment may have an effect on the health
                of the employee and his/ her ability to perform their tasks.
                This may be related to dust, noise, and other risks on site. We
                perform various tests to measure the effect of these risks on
                the employee. This helps to protect both the employee and the
                employer. We can do these tests on any employees in all sectors.
              </div>
              <Link to="/contactUs" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    marginTop: 40,
                    height: 40,
                    width: 260,
                    backgroundColor: colors.secondary,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    lineHeight: 5,
                    letterSpacing: 4,
                  }}
                >
                  CONTACT US
                </div>
              </Link>
            </div>
            <div
              style={{
                width: "100%",
                height: width > 899 ? "50%" : "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: width > 899 ? 40 : 20,
              }}
            >
              {width > 899 ? (
                <img
                  alt="content home"
                  src={require("../../images/content/aboutImage1.png")}
                  style={{ height: "92%", width: "95%" }}
                />
              ) : null}
            </div>
          </div>
          <div
            style={{
              height: "100%",
              flex: 0.4,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              marginTop: width > 899 ? null : 100,
            }}
          >
            <img
              alt="content home"
              src={require("../../images/content/abputImage2.png")}
              style={{
                height: "78%",
                width: "90%",
                borderStyle: "solid",
                borderColor: "#FFFFFF",
                borderWidth: 10,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
