import React, { useState } from "react";
import { colors } from "../../theme/Colors";
import { Outlet, Link } from "react-router-dom";

const Home = ({ width, height }) => {
  const [menu, setMenu] = useState(false);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 100,

          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          alt="content home"
          src={require("../../images/new-icons/mainLogo.png")}
          style={{ height: "95%", marginLeft: width > 899 ? "9%" : null }}
        />
        <div style={{ flex: 1 }} />
        {width > 899 ? (
          <>
            <Link to="/" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  fontSize: 18,
                  width: 80,
                  color: colors.secondary,
                }}
              >
                HOME
              </div>
            </Link>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  width: 80,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                ABOUT
              </div>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  width: 100,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                SERVICES
              </div>
            </Link>
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", marginRight: "10%" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  width: 100,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                CONTACT
              </div>
            </Link>
          </>
        ) : (
          <div
            style={{
              fontWeight: "bold",
              width: 100,
              fontSize: 18,
              color: "#000000",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div onClick={() => setMenu(true)}>MENU</div>
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
                height: menu ? 120 : 0,
                backgroundColor: "#ffffff",
                zIndex: 4,
                right: 0,
                width: "100%",
                top: 0,
                justifyContent: "space-evenly",
                transition: "height 0.3s",
              }}
            >
              <>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      width: 80,
                      color: colors.secondary,
                      textAlign: "center",
                    }}
                  >
                    HOME
                  </div>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    ABOUT
                  </div>
                </Link>
                <Link to="/services" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    SERVICES
                  </div>
                </Link>
                <Link to="/contactUs" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    CONTACT
                  </div>
                </Link>
              </>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          flex: 1,
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <div
          style={{
            // flex: 0.5,
            backgroundColor: colors.primary,
            width: "100%",
            position: "fixed",
            height: "50%",
            bottom: 0,
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            height: "75%",
            width: "90%",

            zIndex: 2,
            maxWidth: 1600,
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img
            alt="content home"
            src={require("../../images/content/homeImage1.png")}
            style={{ height: "95%", width: "98%" }}
          />
          <div
            style={{
              width: width > 899 ? "60%" : "90%",
              height: width > 899 ? 350 : 420,
              borderStyle: "solid",
              backgroundColor: width > 899 ? "#FFFFFF" : "#FFFFFF",
              position: "absolute",
              bottom: width > 899 ? -175 : -200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: width > 899 ? 350 : 420,
            }}
          >
            <img
              alt="content home"
              src={require("../../images/new-icons/welcomeText.png")}
              style={{
                height: width > 899 ? 100 : 100,
                marginBottom: width > 899 ? 10 : null,
              }}
            />
            <div
              style={{
                width: "90%",
                fontSize: 18,
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              Your Health Matters!{" "}
            </div>
            <div
              style={{
                width: "90%",
                fontSize: width > 899 ? 18 : 18,
                textAlign: "center",
              }}
            >
              Our Occupational Health Medical Facility as well as our Mobile
              Unit, is run by an incredible fully qualified medical team here to
              make sure you as an employer or employee receive the best testing
              to protect you and your business!
            </div>
            <Link to="/contactUs" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginTop: 40,
                  height: 40,
                  width: 260,
                  backgroundColor: colors.secondary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  lineHeight: 5,
                  letterSpacing: 4,
                }}
              >
                BOOK APOINTMENT
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
