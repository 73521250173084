import React, { useEffect, useState } from "react";
import {
  ButtonCore,
  ContactUsDisplay,
  EmailPopUp,
  MenuPopUp,
  TopNavBar,
} from "../../components";
import { colors } from "../../theme/Colors";
import { Outlet, Link } from "react-router-dom";

const ContactUs = ({ width, height }) => {
  const [menu, setMenu] = useState(false);
  const [cellnumber, setCellNumber] = useState(false);

  const [emailPopUp, setEmailPopUp] = useState(false);
  const [emailOptions, setEmailOptions] = useState(true);
  const [number, setNumber] = useState(false);
  const [succesText, setSuccessText] = useState("LOADING . . .");

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 100,

          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          alt="content home"
          src={require("../../images/new-icons/mainLogo.png")}
          style={{ height: "95%", marginLeft: width > 899 ? "9%" : null }}
        />
        <div style={{ flex: 1 }} />
        {width > 899 ? (
          <>
            <Link to="/" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  fontSize: 18,
                  width: 80,
                  color: "#000000",
                }}
              >
                HOME
              </div>
            </Link>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  width: 80,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                ABOUT
              </div>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <div
                style={{
                  marginRight: 20,
                  fontWeight: "bold",
                  width: 100,
                  fontSize: 18,
                  color: "#000000",
                }}
              >
                SERVICES
              </div>
            </Link>
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", marginRight: "10%" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  width: 100,
                  fontSize: 18,
                  // color: "#000000",
                }}
              >
                CONTACT
              </div>
            </Link>
          </>
        ) : (
          <div
            style={{
              fontWeight: "bold",
              width: 100,
              fontSize: 18,
              color: "#000000",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div onClick={() => setMenu(true)}>MENU</div>
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
                height: menu ? 120 : 0,
                backgroundColor: "#ffffff",
                zIndex: 4,
                right: 0,
                width: "100%",
                top: 0,
                justifyContent: "space-evenly",
                transition: "height 0.3s",
              }}
            >
              <>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      width: 80,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    HOME
                  </div>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    ABOUT
                  </div>
                </Link>
                <Link to="/services" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    SERVICES
                  </div>
                </Link>
                <Link to="/contactUs" style={{ textDecoration: "none" }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      width: 80,
                      fontSize: 14,
                      // color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    CONTACT
                  </div>
                </Link>
              </>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          flex: width > 899 ? 1 : null,
          height: width > 899 ? null : 800,
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <div
          style={{
            // flex: 0.5,
            backgroundColor: colors.primary,
            width: "100%",
            position: "fixed",
            height: width > 899 ? "40%" : "20%",
            bottom: 0,
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            height: "90%",
            width: "90%",

            zIndex: 2,
            maxWidth: 1600,
            // backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flexDirection: width > 899 ? "row" : "column",
          }}
        >
          <div
            style={{
              height: width > 899 ? "100%" : 800,
              flex: width > 899 ? 0.6 : null,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: width > 899 ? 500 : null,
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: width > 899 ? null : 600,
              }}
            >
              <img
                alt="content home"
                src={require("../../images/new-icons/contactUsText.png")}
                style={{
                  height: width > 899 ? 120 : 100,
                  marginBottom: width > 899 ? 10 : null,
                }}
              />

              <div
                style={{
                  width: "90%",
                  fontSize: width > 899 ? 22 : 18,
                  textAlign: "center",
                }}
              >
                Have Questions or need assistance? Reach out to us - we’re here
                to help! Follow us on our Social Media to stay updated!
              </div>
              <div
                style={{
                  width: "100%",

                  height: width > 899 ? 200 : 450,
                  minHeight: width > 899 ? null : 450,
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  position: "relative",
                  flexDirection: width > 899 ? "row" : "column",
                }}
              >
                <a
                  href="https://wa.me/+27716879779"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "75%" : "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    onClick={() => setCellNumber(true)}
                    alt="content home"
                    src={require("../../images/new-icons/cellNUmber.png")}
                    style={{ height: "70%" }}
                  />
                </a>

                <img
                  onClick={() => setEmailPopUp(true)}
                  alt="content home"
                  src={require("../../images/new-icons/email.png")}
                  style={{ height: width > 899 ? "60%" : "30%" }}
                />
                <a
                  href="tel:+27716879779"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "75%" : "30%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/whatsapp.png")}
                    style={{ height: "70%" }}
                  />
                </a>

                <div
                  style={{
                    position: "absolute",
                    transition: "height 0.3s",
                    width: "100%",
                    height: cellnumber ? "100%" : 0,
                    backgroundColor: "#FFFFFF",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: width > 899 ? "row" : "column",
                  }}
                >
                  <ButtonCore
                    label={"X"}
                    onClick={() => setCellNumber(false)}
                    backgroundColor={colors.secondary}
                    backGroundColorTwo={colors.primary}
                    fontColor={"#ffffff"}
                    fontColor2={"#ffffff"}
                    size={width > 899 ? 60 : "100%"}
                  />
                  <div
                    style={{
                      height: "100%",
                      fontSize: 54,
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: 20,
                    }}
                  >
                    +2771 687 9779
                  </div>
                </div>
              </div>

              {width > 899 ? (
                <div
                  style={{
                    marginTop: 40,
                    height: 40,
                    width: 260,
                    backgroundColor: colors.secondary,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    lineHeight: 5,
                    letterSpacing: 4,
                    position: "absolute",
                    bottom: -30,
                  }}
                >
                  CONTACT US
                </div>
              ) : null}
            </div>
            <div
              style={{
                width: "100%",
                height: width > 899 ? "50%" : "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: width > 899 ? 40 : 20,
              }}
            >
              {width > 899 ? (
                <img
                  alt="content home"
                  src={require("../../images/content/contactUsimage2.png")}
                  style={{ height: "85%", width: "95%" }}
                />
              ) : null}
            </div>
          </div>
          <div
            style={{
              height: "100%",
              flex: width > 899 ? 0.4 : null,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              marginTop: width > 899 ? null : 480,
            }}
          >
            <img
              alt="content home"
              src={require("../../images/content/conatcImage2.png")}
              style={{
                height: "78%",
                width: "90%",
                borderStyle: "solid",
                borderColor: "#FFFFFF",
                borderWidth: 10,
              }}
            />
            {width > 899 ? (
              <div
                style={{
                  // flex: 1,

                  width: "92%",
                  display: "flex",
                  alignItems: "center",
                  maxHeight: 150,
                  maxWidth: 600,
                  justifyContent: "space-evenly",
                  overflow: "hidden",
                }}
              >
                {" "}
                <a
                  href="https://www.google.com/maps/dir//Suite+3+No,+88+Pretoria+Rd,+Rynfield,+Benoni,+1501/@-26.1576994,28.2508747,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x1e953dc7bc7b8b33:0xfcc14b5c79f3ef51!2m2!1d28.3333618!2d-26.1577693?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/location.png")}
                    style={{ height: width > 899 ? "65%" : "30%" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/jobmed84?igsh=eHp6Zmc5a2tmZW5k&utm_source=qr"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/instagram.png")}
                    style={{ height: width > 899 ? "65%" : "30%" }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/BecauseHealthWorks/"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/Facebook.png")}
                    style={{ height: width > 899 ? "65%" : "30%" }}
                  />
                </a>
                <a
                  href="https://www.google.com/search?q=jobmed&rlz=1C5CHFA_enZA1125ZA1125&oq=jobmed&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGD0yBggCEEUYPDIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPdIBCDExMjRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/google.png")}
                    style={{ height: width > 899 ? "65%" : "30%" }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/jobmed-occupational-health/"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/linkedin.png")}
                    style={{ height: width > 899 ? "65%" : "30%" }}
                  />
                </a>
              </div>
            ) : (
              <div
                style={{
                  // flex: 1,

                  width: 300,
                  display: "flex",
                  alignItems: "center",
                  maxHeight: 150,
                  minHeight: 150,
                  maxWidth: 300,
                  minWidth: 300,
                  justifyContent: "space-evenly",
                  overflow: "hidden",
                }}
              >
                {" "}
                <a
                  href="https://www.google.com/maps/dir//Suite+3+No,+88+Pretoria+Rd,+Rynfield,+Benoni,+1501/@-26.1576994,28.2508747,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x1e953dc7bc7b8b33:0xfcc14b5c79f3ef51!2m2!1d28.3333618!2d-26.1577693?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "100%" : null,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/location.png")}
                    style={{
                      height: width > 899 ? "65%" : 50,
                      width: width > 899 ? null : 50,
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/jobmed84?igsh=eHp6Zmc5a2tmZW5k&utm_source=qr"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "100%" : null,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/instagram.png")}
                    style={{
                      height: width > 899 ? "65%" : 50,
                      width: width > 899 ? null : 50,
                    }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/BecauseHealthWorks/"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "100%" : null,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/Facebook.png")}
                    style={{
                      height: width > 899 ? "65%" : 50,
                      width: width > 899 ? null : 50,
                    }}
                  />
                </a>
                <a
                  href="https://www.google.com/search?q=jobmed&rlz=1C5CHFA_enZA1125ZA1125&oq=jobmed&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGD0yBggCEEUYPDIGCAMQRRg8MgYIBBBFGDwyBggFEEUYPdIBCDExMjRqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "100%" : null,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/google.png")}
                    style={{
                      height: width > 899 ? "65%" : 50,
                      width: width > 899 ? null : 50,
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/jobmed-occupational-health/"
                  target="_blank"
                  style={{
                    textDecorationLine: "none",
                    height: width > 899 ? "100%" : null,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/linkedin.png")}
                    style={{
                      height: width > 899 ? "65%" : 50,
                      width: width > 899 ? null : 50,
                    }}
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <EmailPopUp
        width={width}
        height={width > 899 ? "72%" : "100%"}
        display={emailPopUp}
        onCloseClick={() => {
          setEmailPopUp(false);
          setEmailOptions(true);
          setSuccessText("LOADING . . .");
        }}
        onEmailClick={() => {
          navigator.clipboard.writeText("info@jobmed.co.za");
          setEmailPopUp(false);
        }}
        setEmailPopUp={setEmailPopUp}
        emailOptions={emailOptions}
        setEmailOptions={setEmailOptions}
        succesText={succesText}
        setSuccessText={setSuccessText}
      />
    </div>
  );
};

export default ContactUs;
