import React, { useEffect, useState } from "react";
import {
  AboutUsDisplay,
  ContactUsDisplay,
  MenuPopUp,
  ServiceDisplay,
  ServiceDisplayItem,
  TopNavBar,
} from "../../components";
import { colors } from "../../theme/Colors";
import { Outlet, Link } from "react-router-dom";

const Services = ({ width, height }) => {
  const [menu, setMenu] = useState(false);
  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundColor: "#FFFFFF",
          width: width,
          height: height,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 100,

            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="content home"
            src={require("../../images/new-icons/mainLogo.png")}
            style={{ height: "95%", marginLeft: width > 899 ? "9%" : null }}
          />
          <div style={{ flex: 1 }} />
          {width > 899 ? (
            <>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    marginRight: 20,
                    fontWeight: "bold",
                    fontSize: 18,
                    width: 80,
                    color: "#000000",
                  }}
                >
                  HOME
                </div>
              </Link>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    marginRight: 20,
                    fontWeight: "bold",
                    width: 80,
                    fontSize: 18,
                    color: "#000000",
                  }}
                >
                  ABOUT
                </div>
              </Link>
              <Link to="/services" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    marginRight: 20,
                    fontWeight: "bold",
                    width: 100,
                    fontSize: 18,
                    // color: "#000000",
                  }}
                >
                  SERVICES
                </div>
              </Link>
              <Link
                to="/contactUs"
                style={{ textDecoration: "none", marginRight: "10%" }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    width: 100,
                    fontSize: 18,
                    color: "#000000",
                  }}
                >
                  CONTACT
                </div>
              </Link>
            </>
          ) : (
            <div
              style={{
                fontWeight: "bold",
                width: 100,
                fontSize: 18,
                color: "#000000",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div onClick={() => setMenu(true)}>MENU</div>
              <div
                style={{
                  position: "fixed",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "hidden",
                  height: menu ? 120 : 0,
                  backgroundColor: "#ffffff",
                  zIndex: 4,
                  right: 0,
                  width: "100%",
                  top: 0,
                  justifyContent: "space-evenly",
                  transition: "height 0.3s",
                }}
              >
                <>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        width: 80,
                        color: "#000000",
                        textAlign: "center",
                      }}
                    >
                      HOME
                    </div>
                  </Link>
                  <Link to="/about" style={{ textDecoration: "none" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        width: 80,
                        fontSize: 14,
                        color: "#000000",
                        textAlign: "center",
                      }}
                    >
                      ABOUT
                    </div>
                  </Link>
                  <Link to="/services" style={{ textDecoration: "none" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        width: 80,
                        fontSize: 14,

                        textAlign: "center",
                      }}
                    >
                      SERVICES
                    </div>
                  </Link>
                  <Link to="/contactUs" style={{ textDecoration: "none" }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        width: 80,
                        fontSize: 14,
                        color: "#000000",
                        textAlign: "center",
                      }}
                    >
                      CONTACT
                    </div>
                  </Link>
                </>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            flex: 1,
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              // flex: 0.5,
              backgroundColor: colors.primary,
              width: "100%",
              position: "absolute",
              height: "40%",
              bottom: 0,
              zIndex: 1,
            }}
          ></div>
          <div
            style={{
              flex: 1,

              height: "92%",
              width: "80%",
              zIndex: 2,
              marginTop: 10,
              overflowY: "scroll",
              overflowX: "hidden",
              scrollbarWidth: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: width > 899 ? height - 100 : height - 100,
            }}
          >
            <div
              style={{
                minHeight: width > 899 ? 800 : null,
                width: "100%",

                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 0.4,

                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  alt="content home"
                  src={require("../../images/new-icons/services.png")}
                  style={{
                    height: width > 899 ? 120 : 100,
                    marginBottom: width > 899 ? 10 : null,
                  }}
                />
                <div
                  style={{
                    width: "60%",

                    marginTop: 20,
                    fontSize: 20,
                    textAlign: "center",
                  }}
                >
                  Our range of tailored services, designed to meet your needs
                  with precision and care! Your satisfaction and health is our
                  Number One priority!
                </div>
              </div>
              <div
                style={{
                  flex: 0.6,

                  width: "100%",
                  display: "flex",
                  flexDirection: width > 899 ? "row" : "column",
                }}
              >
                <div
                  style={{
                    flex: 0.3,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    widthCheck={width}
                    width={"80%"}
                    height={width > 899 ? "70%" : "80%"}
                    image={require("../../images/content/employesWellness.png")}
                    title={"EMPLOYEE WELLNESS DAY"}
                    info={
                      "Prioritize your employee's well-being is the main way an employer can help employees manage stress. We can assist in planning an annual wellness day for your employees to improve their health and well-being"
                    }
                  />
                </div>
                <div
                  style={{
                    flex: 0.4,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    widthCheck={width}
                    width={"80%"}
                    height={"90%"}
                    marginBottom={width > 899 ? 80 : 0}
                    image={require("../../images/content/contactUsSeriveImage.png")}
                    title={"CONTACT US"}
                  />
                </div>
                <div
                  style={{
                    flex: 0.3,
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    widthCheck={width}
                    height={width > 899 ? "70%" : "80%"}
                    image={require("../../images/content/drugTesting.png")}
                    title={"DRUG TESTING"}
                    info={
                      "We offer screening drug tests that look for signs of one or more illegal or prescription drugs in a urine sample. We can assist in the rehabilitation process."
                    }
                  />
                </div>
              </div>
            </div>
            {/* grop 1 */}
            <div
              style={{
                minHeight: 800,
                width: "100%",
                display: "flex",
                flexDirection: width > 899 ? "row" : "column",
              }}
            >
              <div
                style={{
                  flex: 0.33,

                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    title={"MEDICAL SURVEILLANCE"}
                    image={require("../../images/content/medicalsurvaeilence.png")}
                    info={
                      "Ensure that all clients are fit and healthy at work by the implementation of entry, periodic, pre-placement, post-illness, and exit medicals. In order to create an environment that is safe and without risk to the health of themselves and their fellow employees."
                    }
                    widthCheck={width}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    image={require("../../images/content/mobileMedicals.png")}
                    title={"MOBILE MEDICALS"}
                    info={
                      "Two mobile units are available to conduct medical surveillance on sites nationwide which you can choose from. The first unit is a 4-ton truck that contains 2 audiometric booths and will require an electrical point. The other unit has noise-canceling audiometric equipment(negates the use of an audiometric booth) that wouldn't require an electrical point this unit requires a room on site."
                    }
                    widthCheck={width}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: width > 899 ? 0.33 : null,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: width > 899 ? 40 : null,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {width > 899 ? (
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/services.png")}
                    style={{
                      height: width > 899 ? 100 : 0,
                      marginBottom: width > 899 ? 10 : null,
                      width: 180,
                    }}
                  />
                ) : null}
                <div
                  style={{
                    flex: 0.5,

                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"90%"}
                    image={require("../../images/content/vaccines.png")}
                    title={"VACCINES"}
                    info={
                      "Vaccination is the safe and most effective way to prevent infection and minimize severe outcomes."
                    }
                    widthCheck={width}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,

                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: width > 899 ? null : 20,
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"90%"}
                    image={require("../../images/content/primaryHealthCare.png")}
                    title={"PRIMARY HEALTHCARE"}
                    info={
                      "Primary healthcare provides continuing and comprehensive healthcare for individuals. The service includes acute, chronic, and preventative medical care. as well as examination, diagnosis, and treatment."
                    }
                    widthCheck={width}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: 0.33,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    image={require("../../images/content/travelMedicals.png")}
                    title={"TRAVEL MEDICINE"}
                    info={
                      "A qualified travel medicine sister can assist with any vaccination needed for work or leisure to keep you safe and healthy on your travels."
                    }
                    widthCheck={width}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    image={require("../../images/content/onSiteClinic.png")}
                    title={"ON-SITE CLINC"}
                    info={
                      "Management of on-site clinics(nationwide) which includes, Occupational health, Primary Healthcare as per site requirements. With the utilization of MMS, allows the client to have a fully integrated Medical Management Solution."
                    }
                    widthCheck={width}
                  />
                </div>
              </div>
            </div>
            {/* group 2 */}
            <div
              style={{
                minHeight: 800,
                width: "100%",
                display: "flex",
                marginBottom: 100,
                flexDirection: width > 899 ? "row" : "column",
                marginTop: width > 899 ? null : 400,
              }}
            >
              <div
                style={{
                  flex: 0.33,

                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: width > 899 ? null : 20,
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    title={"TRAINING"}
                    image={require("../../images/content/training.png")}
                    info={
                      "A holistic integrated risk-based management training solution."
                    }
                    widthCheck={width}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    title={"COID"}
                    image={require("../../images/content/CoidCOVID.png")}
                    info={
                      "Our team can assist you with all COID and Occupational related diseases processes to ensure that employees are covered against occupational diseases, injuries, and death."
                    }
                    widthCheck={width}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: 0.33,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: width > 899 ? 80 : 20,
                  justifyContent: "center",
                  alignItems: "center",
                  width: width > 899 ? null : "100%",
                }}
              >
                {width > 899 ? (
                  <img
                    alt="content home"
                    src={require("../../images/new-icons/services.png")}
                    style={{
                      height: width > 899 ? 100 : 0,
                      marginBottom: width > 899 ? 10 : null,
                      width: 180,
                    }}
                  />
                ) : null}
                <div
                  style={{
                    flex: 0.5,

                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"90%"}
                    image={require("../../images/content/mms.png")}
                    title={"MMS"}
                    info={
                      "Our medicals are electronically captured, digitally signed, and securely stored. Diagnostic screening results feed directly into the management system. Client and patient upload integrated with the diagnostic software."
                    }
                    widthCheck={width}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,

                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 30,
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"90%"}
                    image={require("../../images/content/VCt.png")}
                    title={"VCT"}
                    info={
                      "Our qualified staff assists in voluntary counseling and testing for individuals."
                    }
                    widthCheck={width}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: 0.33,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    image={require("../../images/content/chronicMedication.png")}
                    title={"CHRONIC CONDITION MANAGEMENT"}
                    info={
                      "A chronic condition is a health condition that is long-lasting. Jobmed can assist by managing chronic conditions through monthly visits to ensure the condition is controlled and to prevent complications."
                    }
                    widthCheck={width}
                  />
                </div>
                <div
                  style={{
                    flex: 0.5,

                    wdth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ServiceDisplayItem
                    width={"80%"}
                    height={"80%"}
                    image={require("../../images/content/counselling.png")}
                    title={"COUNCELING"}
                    info={
                      "Counseling can empower you. Our qualified counselor can assist with emotional support and possible solutions to your problems."
                    }
                    widthCheck={width}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
